import { i18n } from "@/locales";
import { SU_ZHOU_YUAN_QU } from "@/pocConfig";
import { ApplicationConfig } from "@/config";

export enum RegisterMethods {
  Email = "email",
  Phone = "phone",
}

export enum LoginMethods {
  LDAP = "ldap",
  AppQr = "app-qrcode",
  Password = "password",
  PhoneCode = "phone-code",
  WxMinQr = "wechat-miniprogram-qrcode", // 对应社会化登录的 wechat:miniprogram:qrconnect(小程序扫码登录)
  AD = "ad", // 对应企业身份源的 Windows AD 登录
}

export const getLoginTabsMap = (config: ApplicationConfig) => ({
  [LoginMethods.Password]: SU_ZHOU_YUAN_QU.appIds.includes(config.id)
    ? SU_ZHOU_YUAN_QU.pwdLoginTabText
    : i18n.t("login.pwdLogin"),
  [LoginMethods.PhoneCode]: i18n.t("login.verifyCodeLogin"),
  [LoginMethods.AppQr]: i18n.t("login.appScanLogin"),
  [LoginMethods.WxMinQr]: i18n.t("login.scanLogin"),
  [LoginMethods.LDAP]: i18n.t("login.ldapLogin"),
  [LoginMethods.AD]: i18n.t("login.adLogin"),
});

export const REGISTER_METHODS_MAP = {
  [RegisterMethods.Email]: i18n.t("login.emailRegister"),
  [RegisterMethods.Phone]: i18n.t("login.phoneRegister"),
};

export enum ApplicationMfaType {
  SMS = "SMS",
  EMAIL = "EMAIL",
  OTP = "OTP",
  FACE = "FACE",
  // FINGERPRINT = 'FINGERPRINT',
}

export const ErrorCodes = {
  // 未登录
  LOGIN_REQUIRED: 2020,
  // 用户不存在
  USER_NOT_EXISTS: 2004,
  // TOP MFA 的 error code
  OTP_MFA_CODE: 1635,
  // 手机和短信验证吗 MFA 的 error code
  MSG_MFA_CODE: 1636,
};

/**
 * 密码安全等级
 */
export enum PasswordSecurityLevel {
  LOW = 1,
  MIDDLE = 2,
  HIGH = 3,
}

/**
 * 账号安全等级
 */
export enum AccountSecurityLevel {
  LOW = 1,
  MIDDLE = 2,
  HIGH = 3,
}
export const PASSWORD_SECURITY_LEVEL_CONFIG: Record<
  PasswordSecurityLevel,
  {
    color: string;
  }
> = {
  [PasswordSecurityLevel.LOW]: {
    color: "#E02020",
  },
  [PasswordSecurityLevel.MIDDLE]: {
    color: "#F7B500",
  },
  [PasswordSecurityLevel.HIGH]: {
    color: "#28b1b0",
  },
};

export const ACCOUNT_SCORE_LEVEL_MAP = [
  {
    text: i18n.t("common.low"),
    color: "#E02020",
    range: [0, 75], // 实际最低分也有 60 分
  },
  {
    text: i18n.t("common.middle"),
    color: "#F7B500",
    range: [76, 85],
  },
  {
    text: i18n.t("common.high"),
    color: "#28b1b0",
    range: [86, 100],
  },
];

export const RegisterInfoSessionKey = "_register_session_info_for_complete";

export enum PasswordStrength {
  NoCheck,
  Low,
  Middle,
  High,
}

export const PASSWORD_STRENGTH_TEXT_MAP: Record<
  PasswordStrength,
  {
    placeholder: string;
    validateMessage: string;
  }
> = {
  [PasswordStrength.NoCheck]: {
    placeholder: i18n.t("login.inputPwd"),
    validateMessage: i18n.t("login.inputPwd"),
  },
  [PasswordStrength.Low]: {
    placeholder: i18n.t("login.setPwdLimit1"),
    validateMessage: i18n.t("login.setPwdLimitMsg1"),
  },
  [PasswordStrength.Middle]: {
    placeholder: i18n.t("login.login.setPwdLimit2"),
    validateMessage: i18n.t("login.setPwdLimitMsg2"),
  },
  [PasswordStrength.High]: {
    placeholder: i18n.t("login.login.setPwdLimit3"),
    validateMessage: i18n.t("login.setPwdLimitMsg3"),
  },
};

export enum ScreenSize {
  Mobile = "mobile",
  Desktop = "desktop",
}
export const SCREEN_SIZE_RANG: {
  size: ScreenSize;
  range: [number, number];
}[] = [
  {
    size: ScreenSize.Mobile,
    range: [0, 719],
  },
  {
    size: ScreenSize.Desktop,
    range: [720, Infinity],
  },
];
